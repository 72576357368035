export const astuces = [
  {
    id: "AID_PERMIS_MESAIDES",
    theme: "Aide",
    message: "Vous avez besoin de passer le permis ? Bénéficiez d'au moins 500€ d'aides dès maintenant.",
    lien: "https://mes-aides.francetravail.fr/",
  },
  {
    id: "AID_COMMUNES",
    theme: "Aide",
    message: "Certaines communes mettent à disposition des logements pour l'hébergement des alternants.",
    lien: "",
  },
  {
    id: "AID_ENTRETIEN",
    theme: "Aide",
    message: "Après la candidature, l'entretien ! Préparez-vous à l'aide de ce module !",
    lien: "https://dinum.didask.com/courses/demonstration/60d1adbb877dae00003f0eac",
  },
  {
    id: "AID_MATERIEL",
    theme: "Aide",
    message:
      "Vous allez avoir besoin de matériel pour votre apprentissage (couteaux de cuisine, ciseaux de coiffure, ...) ? Il existe des aides financières au premier équipement !",
    lien: "https://www.service-public.fr/particuliers/vosdroits/F32915",
  },
  {
    id: "AID_QUIT_DOMICILE",
    theme: "Aide",
    message: "Vous devez quitter le domicile familial pour vos études ? Action Logement vous aide à vous installer.",
    lien: "https://www.actionlogement.fr/aide-logement-apprentis",
  },
  {
    id: "AID_FINAN_ABO",
    theme: "Aide",
    message: "La plupart des régions aident au financement des abonnements en transport en commun pour les alternants : renseignez-vous !",
    lien: "",
  },
  {
    id: "AID_1JEUNE_1SOL",
    theme: "Aide",
    message: "De nombreuses aides existent pour les jeunes : pour les connaître, faites une simulation sur 1 jeune 1 solution !",
    lien: "https://www.1jeune1solution.gouv.fr/mes-aides",
  },
  {
    id: "AID_MENTOR_ANAF",
    theme: "Aide",
    message: "Un mentor peut vous épauler tout au long de votre parcours et suivant vos besoins !",
    lien: "https://www.mentorat-apprentissage.fr/",
  },
  {
    id: "EMP_OPTIM",
    theme: "Emploi",
    message: "Entre 4 et 7 employeurs sur 10 recrutent sans déposer d'offre d'emploi : optimisez vos chances en adressant aussi des candidatures spontanées !",
    lien: "",
  },
  {
    id: "EMP_CV_CVDESIGNER",
    theme: "Emploi",
    message: "Besoin d'aide pour concevoir un beau CV ? Vous pouvez le faire gratuitement sur CVdesignr.",
    lien: "https://cvdesignr.com/fr",
  },
  {
    id: "EMP_CV_DIAGORIENTE",
    theme: "Emploi",
    message: "Besoin d'aide pour construire un CV à partir de vos expériences ? Inscrivez-vous gratuitement sur Diagoriente.",
    lien: "https://web-app.diagoriente.beta.gouv.fr/#/inscription?utm_source=lba&utm_campaign=lba-dec2021",
  },
  {
    id: "EMP_EMP_AID",
    theme: "Emploi",
    message:
      "Les employeurs qui embauchent des alternants reçoivent des aides : c'est un bon argument pour convaincre une entreprise qui ne connaît pas l'alternance de vous embaucher !",
    lien: "",
  },
  {
    id: "EMP_AVENIR_PROF",
    theme: "Emploi",
    message: "La loi « Avenir professionnel » a étendu les possibilités de faire un contrat en alternance à l’étranger, dans ou hors de l’Union européenne.",
    lien: "https://ec.europa.eu/eures/portal/jv-se/search?page=1&resultsPerPage=10&orderBy=BEST_MATCH&positionOfferingCodes=apprenticeship",
  },
  {
    id: "EMP_SEC_PUB",
    theme: "Emploi",
    message: "Le secteur public (mairies, ministères, départements, régions, ...) recrute aussi des alternants, pensez-y !",
    lien: "https://www.pass.fonction-publique.gouv.fr/",
  },
  {
    id: "EMP_SALON_ETUDIANT",
    theme: "Emploi",
    message: "Tout au long de l'année, il existe des salons de recrutement (physiques ou virtuels) spécialisés pour l'alternance : renseignez-vous !",
    lien: "https://www.letudiant.fr/etudes/salons.html",
  },
  {
    id: "EMP_SALON_STUDYRAMA",
    theme: "Emploi",
    message: "Tout au long de l'année, il existe des salons de recrutement (physiques ou virtuels) spécialisés pour l'alternance : renseignez-vous !",
    lien: "https://www.studyrama.com/salons",
  },
  {
    id: "EMP_EMAIL_PRO",
    theme: "Emploi",
    message:
      "Les recruteurs font attention à de petits détails ! Professionnalisez vos candidatures en utilisant une adresse email adaptée aux contacts professionnels (par exemple : nom.prenom@email.fr)",
    lien: "",
  },
  {
    id: "EMP_QUAL_RECH",
    theme: "Emploi",
    message: "Motivation, Dynamisme et Présentation soignée : 3 qualités recherchées par les employeurs de jeunes candidats. Mettez-les en avant dans votre candidature !",
    lien: "",
  },
  {
    id: "EMP_SIMUL_SALAIRE",
    theme: "Emploi",
    message: "Quel sera votre salaire en alternance ? Faites une simulation sur le portail de l’alternance.",
    lien: "https://www.alternance.emploi.gouv.fr/simulateur-alternant/etape-1",
  },
  {
    id: "EMP_FINANCE_FORM",
    theme: "Emploi",
    message: "Se former en alternance est gratuit ! Votre employeur prend en charge le financement de votre formation.",
    lien: "",
  },
  {
    id: "EMP_COACHING_ANAF",
    theme: "Emploi",
    message: "Bénéficiez d'un coaching individuel afin d'optimiser votre candidature !",
    lien: "https://autourdesapprentis.fr/pages/inscription-profil",
  },
  {
    id: "FOR_LABEL",
    theme: "Formation",
    message: "Certains établissements de formation obtiennent des labels d'excellence, c'est par exemple le cas des CMQ (Campus des Métiers et Qualifications d'excellence).",
    lien: "https://www.education.gouv.fr/les-campus-des-metiers-et-des-qualifications-5075",
  },
  {
    id: "FOR_PORT_OUV",
    theme: "Formation",
    message: "La plupart des organismes de formations font des journées portes ouvertes : vous avez l'occasion d'échanger avec des alternants !",
    lien: "",
  },
  {
    id: "FOR_COMBIEN_6_MOIS",
    theme: "Formation",
    message: "Combien de personnes ont trouvé un emploi dans les 6 mois après avoir obtenu le diplôme que vous souhaitez préparer ? La réponse ici !",
    lien: "https://www.inserjeunes.education.gouv.fr/diffusion/accueil",
  },
  {
    id: "FOR_HANDICAP",
    theme: "Formation",
    message: "En situation de handicap ? Vous pouvez vous former en alternance ! Le référent handicap de votre formation est là pour vous aider.",
    lien: "",
  },
  {
    id: "ORI_METIER_DIAGORIENTE",
    theme: "Orientation",
    message: "Identifiez les métiers qui vous correspondent avec Diagoriente ! Inscrivez-vous gratuitement.",
    lien: "https://web-app.diagoriente.beta.gouv.fr/#/inscription?utm_campaign=lba-jan2022-orientation",
  },
  {
    id: "ORI_PASSTOWORK",
    theme: "Orientation",
    message: "Vous manquez d'inspiration pour votre CV ? Découvrez vos savoir-être en jouant avec le service Pass to work",
    lien: "https://www.monkey-tie.com/la-bonne-alternance/",
  },
  {
    id: "ORI_PARCOURS_ANAF",
    theme: "Orientation",
    message: "L'ANAF (Association Nationale des Apprentis de France) peut vous aider à tout moment de votre parcours et pour toute question que vous vous posez !",
    lien: "http://www.anaf.fr/",
  },
  {
    id: "ORI_DIAGORIENTE",
    theme: "Orientation",
    message: "Vous hésitez dans le choix d'un métier ? Faites le point avec Diagoriente, un service qui vous accompagne dans la construction de votre orientation professionnelle.",
    lien: "https://diagoriente.beta.gouv.fr/",
  },
  {
    id: "ORI_PREPA_APPR",
    theme: "Orientation",
    message: "Des doutes sur votre orientation ? Pensez à la Prépa-apprentissage : un dispositif qui vous permet de découvrir un ou plusieurs métiers !",
    lien: "https://travail-emploi.gouv.fr/le-ministere-en-action/pic/prepa-apprentissage-pic",
  },
  {
    id: "ORI_FILM_ANAF",
    theme: "Orientation",
    message: "Découvrez le quotidien et les métiers des apprentis en vidéo.",
    lien: "https://www.filmetonjob.com/videos",
  },
]
