import { Box, Flex, Text } from "@chakra-ui/react"
import { includes } from "lodash"
import { useRouter } from "next/router"
import React, { useContext } from "react"

import { SearchResultContext } from "../../context/SearchResultContextProvider"
import InfoBanner from "../InfoBanner/InfoBanner"
import LogoLBA from "../LogoLBA/LogoLBA"
import SearchForm from "../SearchForm/SearchForm"
import ResultFilterAndCounter from "../SearchForTrainingsAndJobs/components/ResultFilterAndCounter"

const WidgetHeader = ({
  handleSearchSubmit,
  isHome = false,
  jobSearchError = undefined,
  partnerJobSearchError = undefined,
  trainingSearchError = undefined,
  isJobSearchLoading = undefined,
  isPartnerJobSearchLoading = undefined,
  isTrainingSearchLoading = undefined,
}) => {
  const router = useRouter()

  const { selectedItem } = useContext(SearchResultContext)

  const handleSearchSubmitFunction = (values) => {
    return handleSearchSubmit({ values })
  }

  const isFicheDetail = selectedItem && includes(router.asPath, "page=fiche") ? true : false
  const formDisplayValue = isFicheDetail ? "none" : isHome ? "block" : ["none", "none", "block"]

  return (
    <Box zIndex={9} display={formDisplayValue} boxShadow={isHome ? "none" : "0 0 12px 2px rgb(0 0 0 / 21%)"} padding="8px">
      {!isHome && (
        <Box>
          <InfoBanner />
        </Box>
      )}
      <Box margin="auto" maxWidth="1310px">
        <Flex alignItems="flex-start">
          {!isHome && <LogoLBA />}
          <Box>
            {isHome && (
              <Text mb={3} as="h1" fontSize={["26px", "29px"]} fontWeight={700}>
                <Text as="span">Trouvez emploi et formation </Text>
                <Text as="span" color="info">
                  en alternance
                </Text>
              </Text>
            )}
            <SearchForm handleSearchSubmit={handleSearchSubmitFunction} isHome={isHome} />
          </Box>
        </Flex>
        {!isHome && (
          <ResultFilterAndCounter
            jobSearchError={jobSearchError}
            partnerJobSearchError={partnerJobSearchError}
            trainingSearchError={trainingSearchError}
            isJobSearchLoading={isJobSearchLoading}
            isPartnerJobSearchLoading={isPartnerJobSearchLoading}
            isTrainingSearchLoading={isTrainingSearchLoading}
            showSearchForm={() => {}}
          />
        )}
      </Box>
    </Box>
  )
}

export default WidgetHeader
